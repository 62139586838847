<template>
  <div min-h-screen flex flex-col justify-between>
    <div>
      <NuxtLoadingIndicator />
      <Navigation />
      <slot />
      <div v-if="!['/', '/kategorie'].includes($route.path)" h-1px w-full border-b="1px app-divide" mt="48px lg:64px" />
      <Incentives mt="48px lg:64px" />
    </div>
    <Cart />
    <Footer />
  </div>
</template>
