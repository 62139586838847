<script lang="ts" setup>
const items = [
  {
    title: 'Materiály první třídy',
    description: 'Výrobky z vysoce kvalitních  materiálu, jako je masivní dřevo,  kov nebo exkluzivní kombinace  těchto prvků.',
    src: '/images/incentives/circle.svg',
  },
  {
    title: 'Sofistikovanost a vkus.',
    description: 'Unikátní tvar a architektonické  prvky, které přitahují pozornost.',
    src: '/images/incentives/pencil.svg',
  },
  {
    title: 'Praktičnost a funkčnost Praktičnost a funkčnost',
    description: 'Navzdory luxusním vzhledu, jsou  výrobky praktické a funkční.  Můžou být umístěné v moderním  interiéru nebo v klasickém prostředí',
    src: '/images/incentives/tools.svg',
  },
]
</script>

<template>
  <div px-24px app-container>
    <h1 text="24px lg:32px app-text-gray-dark" text-center font-extrabold>
      Proč si vybrat naše produkty?
    </h1>
    <div grid grid-cols="1 lg:3" gap-y-48px lg:gap-x-32px mt="48px lg:60px">
      <div v-for="item in items" :key="item.title" flex flex-col items-center>
        <NuxtImg :src="item.src" width="64" height="64" />
        <h2 mt-24px text="18px app-text-gray-dark center" font-bold leading-24px>
          {{ item.title }}
        </h2>
        <p mt-4px text="16px app-text-gray-light center" font-regular leading-24px>
          {{ item.description }}
        </p>
      </div>
    </div>
  </div>
</template>
