<script lang="ts" setup>
const { open, toggleOpen } = useCart()

const { getThumbnail } = useDirectusFiles()
const { orderItems, totalPrice, removeItem } = useCart()

function checkout () {
  toggleOpen(false)
  navigateTo('/objednavka')
}

function openDetail (slug: string) {
  toggleOpen(false)
  navigateTo(`/produkt/${slug}`)
}
</script>

<template>
  <HeadlessTransitionRoot as="template" :show="open">
    <HeadlessDialog class="relative z-10" @close="open = false">
      <HeadlessTransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </HeadlessTransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <HeadlessTransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <HeadlessDialogPanel class="pointer-events-auto w-screen max-w-sm md:max-w-lg">
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="flex-1 overflow-y-auto pt-24px lg:pt-48px px-24px lg:px-48px">
                    <div class="flex items-start justify-between">
                      <HeadlessDialogTitle class="text-28px text-app-text-blue" leading-40px font-extrabold>
                        Nákupní košík {{ !orderItems.length ? 'je prázdný' : '' }}
                      </HeadlessDialogTitle>
                    </div>

                    <div class="mt-8">
                      <div class="flow-root">
                        <ul role="list" class="-my-6 divide-y divide-gray-200">
                          <li v-for="item in orderItems" :key="item.item.id" class="flex flex-col sm:flex-row py-6" cursor-pointer @click="openDetail(item.item.slug)">
                            <div class="sm:h-24 sm:w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                              <NuxtImg :src="getThumbnail(item.item.thumbnail, { format: 'webp' })" class="h-full w-full object-cover object-center" />
                            </div>

                            <div class="sm:ml-4 flex flex-1 justify-between mt-12px sm:mt-0">
                              <div flex flex-col justify-between sm:justify-between>
                                <div class="flex justify-between text-base w-full font-medium text-gray-900">
                                  <h3 font-inter text="15px app-text-blue" leading-24px font-medium>
                                    {{ item.quantity > 1 ? `${item.quantity}x` : '' }} {{ item.item.title }}
                                  </h3>
                                </div>
                                <p text-12px text-app-text-gray-light>
                                  {{ item.selectedColor.title }}, {{ item.selectedDecor.title }}, {{ item.selectedSize.title }}
                                </p>
                                <button hidden sm:flex type="button" items-center justify-center border="1px app-stroke" py-3px px-12px text="13px app-text-blue" leading-20px font-medium sm:mt-12px @click.stop="removeItem(item)">
                                  Odebrat
                                </button>
                              </div>
                              <div flex flex-col justify-between>
                                <p class="lg:ml-4" font-inter text="14px app-text-blue" font-medium whitespace-nowrap>
                                  {{ formatPrice(item.item.price) }} Kč
                                </p>
                                <button type="button" flex sm:hidden items-center justify-center border="1px app-stroke" py-3px px-12px text="12px app-text-blue" leading-20px font-medium mt-8px @click.stop="removeItem(item)">
                                  Odebrat
                                </button>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
                    <div class="flex justify-between text-base font-medium text-app-text-blue">
                      <p text-app-text-gray-light>
                        Celkem:
                      </p>
                      <p v-if="totalPrice">
                        {{ formatPrice(totalPrice) }} Kč
                      </p>
                    </div>
                    <div class="mt-6">
                      <button :disabled="orderItems.length < 1" type="button" class="flex items-center justify-center w-full border border-transparent bg-app-text-blue disabled:text-gray  px-6 py-3 text-base font-medium text-white shadow-sm hover:enabled:bg-app-text-blue/95" @click="checkout()">
                        Pokračovat
                      </button>
                    </div>
                  </div>
                </div>
              </HeadlessDialogPanel>
            </HeadlessTransitionChild>
          </div>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>
