<script lang="ts" setup>
const navigation = [
  { name: 'Kategorie', href: '/kategorie' },
  { name: 'Realizace', href: '/realizace' },
  { name: 'O Nás', href: '/o-nas' },
  { name: 'Kontakt', href: '/kontakt' },
]

const { orderItems, toggleOpen: openCart } = useCart()
const mobileMenuOpen = ref(false)
</script>

<template>
  <header bg-white>
    <nav flex items-center justify-between p-24px lg:px-32px aria-label="Global">
      <div flex lg:flex-1>
        <NuxtLink to="/" m--8px p-8px>
          <NuxtImg h="32px" src="/images/logo.svg" alt="Logo" />
        </NuxtLink>
      </div>
      <div flex lg:hidden mr--12px>
        <button relative type="button" my--12px inline-flex items-center justify-center rounded-md p-12px text-app-text-dark hover:text-app-text="dark/80" @click="openCart()">
          <Icon name="i-heroicons-solid-shopping-cart" text-24px focus:outline-none />
          <ClientOnly>
            <div v-if="orderItems.length" absolute w-16px h-16px bg-app-text-gray-dark text-white text-12px rounded-full top-4px right-4px>
              {{ orderItems.length }}
            </div>
          </ClientOnly>
        </button>
        <button type="button" my--12px inline-flex items-center justify-center rounded-md p-12px text-app-text-dark hover:text-app-text="dark/80" @click="mobileMenuOpen = true">
          <Icon name="i-heroicons-solid-bars-3" text-24px />
        </button>
      </div>
      <div hidden lg:flex lg:gap-x-48px>
        <NuxtLink v-for="item in navigation" v-slot="{ isActive }" :key="item.name" :to="item.href" class="text-16px text-app-text-light hover:text-app-text-dark" font-medium prefetch>
          <span :class="{ 'text-app-text-gray-dark': isActive }">{{ item.name }}</span>
        </NuxtLink>
      </div>
      <div hidden lg:flex lg:flex-1 lg:justify-end gap-32px>
        <button type="button" relative @click="openCart()">
          <Icon z-10 name="i-heroicons-solid-shopping-cart" focus:outline-none text="24px app-text-gray-icon hover:app-text-gray-dark" />
          <ClientOnly>
            <div v-if="orderItems.length" absolute w-16px h-16px bg-app-text-gray-dark text-white text-12px rounded-full top--4px right--8px>
              {{ orderItems.length }}
            </div>
          </ClientOnly>
        </button>
      </div>
    </nav>
    <HeadlessDialog id="headless-mobile-menu-dialog" as="div" class="lg:hidden" :open="mobileMenuOpen" @close="mobileMenuOpen = false">
      <div fixed inset-0 z-10 />
      <HeadlessDialogPanel id="headless-mobile-menu-dialog-panel" fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white p-24px sm:max-w-sm sm:ring-1 sm:ring-app-stroke>
        <div flex items-center justify-between>
          <a href="#" m--8px p-8px>
            <NuxtImg block sm:hidden h="32px" src="/images/logo.svg" alt="Logo" />
          </a>
          <button type="button" m--12px rounded-md p-12px text-app-text-dark hover:text-app-text="dark/80" @click="mobileMenuOpen = false">
            <Icon name="i-heroicons-solid-x-mark" text-24px aria-hidden="true" />
          </button>
        </div>
        <div mt-24px flow-root>
          <div my--24px divide-y divide="2px app-stroke">
            <div space-y-2 py-24px>
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="mx--12px block rounded-lg px-12px py-8px text-base font-semibold leading-7 text-app-text-gray-light hover:text-app-text-gray-dark hover:bg-gray-50">{{ item.name }}</a>
            </div>
          </div>
        </div>
      </HeadlessDialogPanel>
    </HeadlessDialog>
  </header>
</template>
